import * as React from "react";
import { Link } from "react-router-dom";

export interface ButtonLinkProps {
  /**
   * the button label
   */
  label?: string;
  /**
   * the boostrap class to apply
   */
  className?: string;
  /**
   * the route to navigate to
   */
  to: string;
  /**
   * target to open new tab or window
   */
  target?: string;
  children: React.ReactNode;
}

export const LinkButton: React.FC<ButtonLinkProps> = ({ to, className, label, children, target }) => (
  <Link className={className ? className : "btn btn-lg btn-primary"} target={target} to={to}>
    {children}
    {label}
  </Link>
);
