import * as React from "react";
import { map } from "lodash";
import { IdAndLabel } from "common/common.typings";
import { Icon } from "../Icon/Icon";

export interface Props {
  selectedValue?: any;
  defaultValue?: string;
  isFetching?: boolean;
  style?: React.CSSProperties;
  className?: string;
  disabled?: boolean;
  options?: Array<IdAndLabel<string>>;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  required?: boolean;
  emptyItem?: boolean;
}

export const DropDown: React.FC<Props> = (props: Props) => {
  const {
    options,
    className,
    style,
    selectedValue,
    defaultValue,
    disabled,
    onChange,
    isFetching,
    required,
    emptyItem,
  } = props;
  return (
    <div className={`form-group ${className}`}>
      <select
        className="form-control required"
        style={style}
        defaultValue={defaultValue}
        value={selectedValue}
        disabled={disabled}
        onChange={e => onChange(e)}
        required={required}
        title={selectedValue}
      >
        {emptyItem && <option label={""} key={""} value={""} />}
        {map(options, key => (
          <option label={key.label} key={key.id} value={key.id} />
        ))}
      </select>
      {isFetching && (
        <div className="input-group-append">
          <span className="input-group-text">
            <Icon iconName={"sync"} />
          </span>
        </div>
      )}
    </div>
  );
};
