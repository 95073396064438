import * as React from "react";
import { NotFound } from "components/layout/NotFound/NotFound";
import { ROUTES } from "common/routes";
import { Navigate, Route, Routes } from "react-router";
import ForecastSearchContainer from "components/searchFilters/Container/forecastSearchContainer";
import ForecastCreateContainer from "components/forecastCreate/container/forecastCreateContainer";
import { ForecastDetailContainer } from "components/ForecastDetails/ForecastDetailContainer";

/**
 * contains all the bindings from routes to application components
 * https://reacttraining.com/react-router/web/api/Switch
 */
export const AppRoutes: React.FunctionComponent = () => (
  <Routes>
    <Route path={ROUTES.HOME} element={<Navigate to={ROUTES.SEARCH} />} />
    <Route path={ROUTES.SEARCH} element={<ForecastSearchContainer />} />
    <Route path={ROUTES.CREATE} element={<ForecastCreateContainer />} />
    <Route path={ROUTES.PROJECTION_DETAIL} element={<ForecastDetailContainer />} />
    <Route path={ROUTES.NOT_FOUND} element={<NotFound shown={true}/>} />
    <Route path="*" element={<NotFound shown={true}/>} />
  </Routes>
);
