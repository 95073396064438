import * as React from "react";
import { Footer } from "components/layout/Footer/Footer";
import { Header } from "components/layout/Header/Header";

interface MainLayoutProps {
  children: React.ReactNode;
}

// eslint-disable-next-line react/prop-types
export const MainLayout: React.FC<MainLayoutProps> = ({children}: MainLayoutProps)  => {
  return (
    <>
      <Header />
      <div className="container-fluid">{children}</div>
      <Footer />
    </>
  );
};
