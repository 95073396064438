import { LicenseManager } from "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import * as React from "react";
import { agGridLicense } from "utils/ag-grid-license";
import { SearchInput } from "components/common/SearchInput/SearchInput";
import { Forecast } from "components/entities.typing";
import { Button } from "components/common/Buttons/Button";
import { ForecastEditAction } from "../forecastEditAction";
import TotalBottomData from "../Totals/totalBottomData";
import { Navigate, Route, Routes } from "react-router-dom";
import { ROUTES } from "common/routes";
import { Icon } from "components/common/Icon/Icon";
import { SearchFiltersModel } from "components/searchFilters/Filters/searchFilters.typings";
import { decimalFormatter } from "common/helpers";
import ForecastCreateContainer from "components/forecastCreate/container/forecastCreateContainer";
import { ColDef } from "ag-grid-community";

LicenseManager.setLicenseKey(agGridLicense);

interface Props {
  forecastsList: Forecast[];
  isNewForecastDisabled: boolean;
  searchFiltersModel: SearchFiltersModel;
}

const ForecastsList: React.FC<Props> = ({ forecastsList, isNewForecastDisabled, searchFiltersModel }: Props) => {
  const [gridApi, setGridApi] = React.useState(null);
  const [disableExport, setDisableExport] = React.useState(true);
  const [isNewForecastClicked, setIsNewForecastClicked] = React.useState<boolean>(false);
  const gridRef = React.useRef();

  const onGridReady = params => {
    setGridApi(params.api);
    params.api.sizeColumnsToFit();

    window.addEventListener("resize", function() {
      setTimeout(function() {
        params.api.sizeColumnsToFit();
      });
    });
  };

  const onFilterTextBoxChanged = event => {
    const value = event?.target.value;
    gridApi.setQuickFilter(value);
    setDisableExport(gridApi.rowModel.rowsToDisplay.length === 0);

    if (gridApi.rowModel.rowsToDisplay.length === 0) {
      gridApi.showNoRowsOverlay();
    } else {
      gridApi.hideOverlay();
    }
  };

  React.useEffect(() => {
    if (forecastsList) {
      setDisableExport(forecastsList.length === 0 || gridApi?.rowModel.rowsToDisplay.length === 0);
    }
  }, [forecastsList, gridApi]);

  const onExportClick = () => {
    gridApi.exportDataAsExcel();
  };
  const onNewForecastClick = () => {
    setIsNewForecastClicked(true);
  };

  const amountCellStyle = { textAlign: "right" };
  const formatValue = (value: string): string => (value !== "" ? decimalFormatter(parseFloat(value), 2) : "-");

  const statusComponent = (params) => {
    return (
      params.value !== null && (
        <span>
          <span className="text-small badge badge-info badge-prepend-square"> { params.value} </span>
        </span>
      )
    );
  }
  
  const lastAdjustementByComponent = (lastAdjustementBy) => {
    return (
      lastAdjustementBy.value !== "" && (
        <span> {lastAdjustementBy.value} </span>
      )
    );
  }

  const editComponent = (id) => {
    return <ForecastEditAction projectionId={id.value} />;
  }

  const columns: ColDef<Forecast>[] =  [
    { field: "bl", headerName: "Business line" },
    { field: "ssbl", headerName: "Sub sub business line"},
    { field: "businessProvider", headerName: "Business provider name"},
    { field: "primaryObligor", headerName: "Primary obligor name"},
    { field: "productLevel2", headerName: "Product level 2"},
    { field: "productLevel3", headerName: "Product level 3"},
    { field: "productLevel4", headerName: "Product level 4"},
    { field: "originatingCountry", headerName: "Originating country"},
    { field: "originatingEntity", headerName: "Originating entity"},
    { field: "decName", headerName: "Dec name"},
    { field: "isIb", headerName: "Is IB"},
    { field: "nbiForecast", headerName: "NBI forecast (€)", valueFormatter: (params) => formatValue(params.value), cellStyle: amountCellStyle},
    { field: "rwaForecast", headerName: "RWA forecast (€)", valueFormatter: (params) => formatValue(params.value), cellStyle: amountCellStyle},
    { field: "status", cellRenderer: statusComponent, headerName: "Status"},
    { field: "lastAdjustementBy", cellRenderer: lastAdjustementByComponent, headerName: "Last adjustement by"},
    { field: "id", headerName:"Action", cellRenderer: editComponent}
  ];

  return (
    <>
      {isNewForecastClicked && (
        <Routes>
          <Route path={ROUTES.CREATE} element={<ForecastCreateContainer />} />
          <Route path="*" element={<Navigate to={ROUTES.CREATE} state={searchFiltersModel} />} />
        </Routes>
      )}
      <hr />
      <div className="row">
        <div className="form-group col-md-12">
          <TotalBottomData forecastsList={forecastsList} />
        </div>
      </div>
      <div>
        <div className="form-group">
          <h4>Results</h4>
        </div>
        <div className="row">
          <div className="form-group col-md-3">
            <SearchInput
              id="forecast-filter"
              iconName="search"
              onChange={onFilterTextBoxChanged}
              className="form-control"
              placeHoldertext="Quick search"
            />
          </div>
          <div className="form-group col-md-9">
            <Button
              className="ml-1 btn btn-md btn-primary float-right"
              disabled={isNewForecastDisabled}
              onClick={onNewForecastClick}
              tooltip="Only available if no results found below"
            >
              <Icon iconName="add" className="mr-1" />
              New forecast
            </Button>
            <Button className="btn btn-md btn-default float-right" disabled={disableExport} onClick={onExportClick}>
              <i className="icon mr-1">file_download</i>Download
            </Button>
          </div>
        </div>
      </div>

      <div className="ag-theme-alpine">
        <AgGridReact
          ref={gridRef}
          defaultColDef={{
            sortable: true,
            filter: true,
            resizable: true,
          }}
          domLayout="autoHeight"
          rowData={forecastsList}
          pagination={true}
          paginationPageSize={20}
          onGridReady={onGridReady}
          suppressHorizontalScroll={true}
          enableCellExpressions={true}
          columnDefs={columns}
        >
        </AgGridReact>
      </div>
    </>
  );
};
 
export default ForecastsList;
